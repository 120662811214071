<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";
// import {
//   required,
//   email,
//   minLength,
//   sameAs,
//   maxLength,
//   minValue,
//   maxValue,
//   numeric,
//   url,
//   alphaNum,
// } from "vuelidate/lib/validators";

export default {
    page: {
        title: "Pass",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Gestion des Lots",
            tmpCoverFile: null,
            file: null,
            eventId: 0,
            fileModif: null,

            parrainRow: null,
            totalRows: 1,
            currentPage: 1,
            currentPageActif: 1,
            currentPagePasse: 1,
            perPage: 100,
            perPagePasse: 100,
            perPageActif: 100,
            pageOptions: [100, 250, 500],
            filter: null,
            filterActif: null,
            filterPast: null,
            codeevent: [],
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            fields: [
                { key: "libelle", sortable: true, label: "Libellé" },
                { key: "status", sortable: true, label: "Status" },
                { key: "type", sortable: false, label: "Type" },
                { key: "date", sortable: false, label: "Date" },
                { key: "action", sortable: true, label: "Action" },
            ],

            fieldsWinners: [
                { key: "libelle", sortable: true, label: "Libellé" },
                { key: "winner", sortable: true, label: "Gagnant" },
                { key: "telephone", sortable: true, label: "Téléphone" },
                { key: "userType", sortable: false, label: "Type de service" },
                { key: "ride", sortable: true, label: "Trajet" },
                { key: "date", sortable: true, label: "Date du trajet" },
                { key: "status", sortable: true, label: "Statut" },
                { key: "action", sortable: false, label: "Action" },


            ],
            fieldsLosers: [
                //{ key: "libelle", sortable: true, label: "Libellé" },
                { key: "winner", sortable: true, label: "User" },
                //{ key: "telephone", sortable: true, label: "Téléphone" },
                { key: "userType", sortable: false, label: "Type de service" },
                { key: "ride", sortable: true, label: "Trajet" },
                { key: "date", sortable: true, label: "Date d'obtention" },
                { key: "dateGain", sortable: false, label: "Date de jeu" },

                { key: "status", sortable: true, label: "Statut" },
                //{ key: "action", sortable: false, label: "Action" },


            ],

            options: [
                {
                    id: 1,
                    text: 'GAGNANT',
                    value: 1
                },
                {
                    id: 2,
                    text: 'PERDANT',
                    value: 0
                }
            ],
            state: [
                {
                    id: 1,
                    text: 'ACTIF',
                    value: true
                },
                {
                    id: 2,
                    text: 'INACTIF',
                    value: false
                }
            ],
            giftType: [
                {
                    id: 1,
                    text: 'REDUCTION',
                    value: 'REDUCTION'
                },
                {
                    id: 2,
                    text: 'BIEN',
                    value: 'BIEN'
                }
            ],
            gift: {
                libelle: "",
                active: false,
                sort: null,
                giftType: "",
                percentage: 0,


            },
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            showDelete: false,
            showEdit: false,
            showValidate: false,
            showCreateGift: false,
            showUpdateGift: false,
            showdisable: false,
            showActive: false,
            erreur: false,
            showRecharge: false,
            headerBgVariant: "warning",
            headerTextVariant: "info",
            bodyBgVariant: "light",
            showpopGainParrain: false,
            showpopGainMembre: false,
            startValue: 1,
            endValue: 500,
            parbon: 500,
            totalValueActif: 0,
            totalValuePasse: 0,
            userChargeLoader: false,
            newDataEvenement: [],
            allGiftdata: [],
            wonGiftdata: [],
            loseGiftdata:[],
            recherche: "",
            currentGiftId: 0,
            loadAttente: false,
            loadActif: false,
            loadPasse: false,
        };
    },

    omputed: {
        rows() {
            return this.allGiftdata.length;
        },
    },
    validations: {},

    mounted() {
        this.allGift();
        this.giftWon();
        this.giftLose();
        //this.passActifList();
        // this.evenementInactifList();
        // this.evenementPasseList();
        this.init();
        this.passId = this.$route.params.passId;


        setTimeout(() => {

            this.loadAttente = false
            this.loadActif = false
            this.loadPasse = false




        }, 3000);

    },

    methods: {


        async init() {

            this.giftLose()


        },




        async giftLose() {

            const data = await apiRequest(
                "GET",
                'lose-ones',
                undefined,
                false
            )
           

            if (data && data.data) {


                const giftFormatTableActif = data.data.map((oneGift) => {

                    //console.log()

                    // oneGift.sort == 0 ? this.type = "Perdant" : this.type = "Gagnant";
                    oneGift.active == true ? this.status = "ACTIF" : this.status = "INACTIF";
                    return {
                        id: oneGift.id,
                        userId: oneGift.user.id,
                        date: new Date(oneGift.dateChance).toLocaleString("FR-fr"),
                        dateGain: new Date(oneGift.dateGain).toLocaleString("FR-fr"),
                        winner: oneGift.user.firstname + " " + oneGift.user.lastname,
                        telephone: oneGift.user.numero,
                        ride: oneGift.trajet.lieuDepart + " ➳ " + oneGift.trajet.lieuArrive,
                        userType: oneGift.trajet ? 'COVOITURAGE': 'VTC' ,
                        status: this.status,
                        //discount: oneGift.discount,


                    }
                });

                this.loseGiftdata = giftFormatTableActif;
                //console.log("event saved", giftFormatTableActif)

                //this.totalValueActif = this.allGiftdata.length;
            }





        },
        async giftWon() {


            const data = await apiRequest(
                "GET",
                'lucky-ones',
                undefined,
                false
            );

            if (data && data.data) {


                const giftFormatTableActif = data.data.map((oneGift) => {

                    // oneGift.sort == 0 ? this.type = "Perdant" : this.type = "Gagnant";
                    console.log(oneGift);
                    oneGift.active == true ? this.status = "ACTIF" : this.status = "INACTIF";
                    return {
                        id: oneGift.id,
                        userId: oneGift.user.id,
                        libelle: oneGift.lot.libelle,
                        date: new Date(oneGift.dateGain).toLocaleString("FR-fr"),
                        winner: oneGift.user.firstname + " " + oneGift.user.lastname,
                        telephone: oneGift.user.numero,
                        ride: oneGift.trajet ? oneGift.trajet.lieuDepart + " ➳ " + oneGift.trajet.lieuArrive : oneGift.course.lieuDepart + " ➳ " + oneGift.course.lieuArrive,
                        userType: oneGift.trajet ? 'COVOITURAGE ': 'VTC' ,
                        status: this.status,
                        discount: oneGift.discount,


                    }
                });

                this.wonGiftdata = giftFormatTableActif;
               

                this.totalValueActif = this.allGiftdata.length;
            }
        },

        async allGift() {


            const data = await apiRequest(
                "GET",
                'lots',
                undefined,
                false
            );

            //console.log("pass Id",this.eventId);
           


            if (data && data.data) {


                const giftFormatTableActif = data.data.map((oneGift) => {

                    oneGift.sort == 0 ? this.type = "Perdant" : this.type = "Gagnant";
                    oneGift.active == true ? this.status = "Actif" : this.status = "Inactif";
                    return {
                        id: oneGift.id,
                        libelle: oneGift.libelle,
                        type: this.type,
                        status: this.status,
                        date: new Date(oneGift.dateCreation).toLocaleString("FR-fr")


                    }
                });

                this.allGiftdata = giftFormatTableActif;
               

                this.totalValueActif = this.allGiftdata.length;
            }
        },

        async addGift() {

            // const selectedSort = this.options.find(o => o.value === this.sort)  
            // const selectedActive = this.state.find(s => s.value === this.active)
           



            try {

                const addGift = apiRequest(
                    "POST",
                    "lots/add",
                    {
                        libelle: this.gift.libelle,
                        active: this.gift.active,
                        sort: this.gift.sort,
                        type: this.gift.giftType,
                        pourcentage: this.gift.percentage,


                    }
                )

                if (addGift) {
                    this.showCreateGift = false;
                    this.$toast.success("NOUVEAU LOT AJOUTÉ AVEC SUCCÈS", {
                        position: "bottom-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });

                }
                this.emptyEditRow();
                this.giftWon();
                this.allGift();
                //console.log("add Gift", addGift)·

            } catch (error) {

            this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
               
            }



        },

        async updateGift() {

            // const selectedSort = this.options.find(o => o.value === this.sort)  
            // const selectedActive = this.state.find(s => s.value === this.active)
           

            try {
                const giftId = +this.currentGiftId

                const updateGift = apiRequest(
                    "POST",
                    "lots/update",
                    {
                        idLot: giftId,
                        libelle: this.gift.libelle,
                        active: this.gift.active,
                        sort: this.gift.sort,
                    }
                )

                if (updateGift) {
                    this.showCreateGift = false;
                    this.$toast.success("Lot modifié avec succès", {
                        position: "bottom-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false,
                    });

                }
                this.emptyEditRow();
                this.giftWon();
                this.allGift();
                //console.log("add Gift", addGift)·

            } catch (error) {

                this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
               
            }



        },



        emptyEditRow() {
            this.gift = {
                libelle: "",
                active: false,
                sort: 0



            }
        },

        editRow(row) {

            this.showUpdateGift = true;
            this.currentGiftId = row.id;
            this.gift.libelle = row.libelle;
            this.gift.active = row.status;
            this.gift.sort = row.type;

        },


        onFilteredInactif(filteredItems) {
            this.totalValueInactif = filteredItems.length;
            this.currentPage = 1;
        },
        deactivateRow(row) {
            this.showdisable = true;
            this.currentGiftId = row.id;
        },
        deleteRow(row) {
            this.showDelete = true;
            this.currentGiftId = row.id;
        },
        activeRow(row) {
            this.showActive = true;
            this.currentGiftId = row.id;
        },
        closeModal() {
            this.showDelete = false;
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            //this.create();
        },
        tooltipForm() {
            this.submitform = true;
            //this.submitted = true;
            this.formSubmit();
            this.$v.$touch();
        },
        // async onUpdateEvent() {

        //     try {

        //        

        //  
        //   const numPassId = +this.currentGiftId
        //   const updatePass = await apiRequest(
        //     "PUT",
        //     "evenement/tiket-pass-update",
        //     {
        //       passId: numPassId,
        //       pass: this.gift.passName,
        //       montant:this.gift.amount,
        //       nombreJours:this.gift.days,
        //       dateLimite:`${this.gift.dateLimite}T${this.gift.heureLimite}:00.000`
        //     },
        //     false
        //   );



        //   if (updatePass && updatePass.data) {
        //    

        //     this.passActifList();


        //     this.showEditEvent = false;

        //     this.$toast.success("Information  modifiée avec succès", {
        //       position: "bottom-right",
        //       timeout: 2000,
        //       closeOnClick: true,
        //       pauseOnFocusLoss: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       draggablePercent: 0.6,
        //       showCloseButtonOnHover: false,
        //       hideProgressBar: true,
        //       closeButton: "button",
        //       icon: true,
        //       rtl: false,
        //     });
        //     this.emptyEditRow()
        //   }

        //     } catch (error) {
        //       
        //     }

        goToUser(row) {

            window.open(`/user/user_info/${row.userId}`, "_blank", "noreferrer")

        },



        // },
        confirmValidation(row) {
            this.showValidate = true;
            this.currentGiftId = row.id;
        },

        async validateGain() {
            this.showValidate = false;

           

            const validate = await apiRequest(
                "POST",
                "remise-gain",
                { id: this.currentGiftId },
                false
            );

            if (validate) {


                this.$toast.success("Gain validé avec succèss", {
                    position: "bottom-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });
                this.giftWon();
                this.allGift();
                this.showDelete = false;

            }
        },



        // async deletePass() {
        //     this.showDelete = false;

        //     const update = await apiRequest(
        //         "POST",
        //         "evenement/delete",
        //         { id: this.currentGiftId, visible: false },
        //         false
        //     );

        //     if (update) {


        //         this.$toast.success("Information supprimée avec succès", {
        //             position: "bottom-right",
        //             timeout: 2000,
        //             closeOnClick: true,
        //             pauseOnFocusLoss: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             draggablePercent: 0.6,
        //             showCloseButtonOnHover: false,
        //             hideProgressBar: true,
        //             closeButton: "button",
        //             icon: true,
        //             rtl: false,
        //         });
        //         this.evenementInactifList()
        //         this.passActifList();
        //         this.evenementPasseList();
        //         this.showDelete = false;

        //     }
        // },






        // setEventAdress: function (place) {

        //   if (place) {       
        //     this.gift.adresseEvent = place.formatted_address;
        //     this.gift.longitude = place.geometry.location.lng();
        //     this.gift.latitude = place.geometry.location.lat();
        //   }
        // },

    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-md-12">

                <div class="card">
                    <div class="containerDot" v-if="loadAttente == true || loadActif == true || loadPasse == true">
                        <span class="loading">Chargement en cours... </span>

                    </div>
                    <div class="card-header">

                    </div>

                    <div class="card-body">
                        <div class="row d-flex justify-content-right p-2"
                            style="justify-content: right; text-align: right;">
                            <div class="card-title justify-content-right text-right"
                                style="justify-content: right; text-align: right;">
                                <div class="justify-content-right text-right"
                                    style="justify-content: right; text-align: right;">

                                    <button class="add-gift" @click="showCreateGift = true">Ajouter un lot</button>

                                </div>

                            </div>

                        </div>
                        <form>
                            <b-tabs nav-class="nav-tabs-custom">

                                <b-tab title-link-class="p-3">

                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Édition</a>
                                    </template>
                                    <div class="row d-flex justify-content-between align-items-center mt-4">
                                        <div class="p-3">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <!-- Search -->
                                        <div class="inputCol p-3">
                                            <div class="input">
                                                <input type="search" id="serchInput" placeholder="Rechercher..."
                                                    v-model="filterActif" />
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="allGiftdata
                                            " :fields="fields" responsive="sm" :per-page="perPage"
                                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                            <template v-slot:cell(libelle)="row">
                                                <div style="text-align:left">
                                                    {{ row.item.libelle }}
                                                </div>

                                            </template>
                                            <template v-slot:cell(action)="row">
                                                <a v-if="row.item.visible == true" href="javascript:void(0);"
                                                    class="text-danger" @click="deactivateRow(row.item)" v-b-tooltip.hover
                                                    title="désactiver">
                                                    <i class="mdi mdi-close font-size-18"></i>
                                                </a>

                                                <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                                                    @click="editRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-pencil font-size-18"></i>
                                                </a>


                                                <!-- <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                                                    @click="deleteRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-delete font-size-18"></i>
                                                </a> -->

                                            </template>

                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">

                                                    <b-pagination v-model="currentPageActif" :total-rows="totalValueActif"
                                                        :per-page="perPageActif" :aria-controls="trajet"></b-pagination>
                                                </ul>
                                            </div>
                                            <!--  <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
           
                            <li>
                              <span class="interval"> {{ startValue }}</span> -
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition> -->
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab title-link-class="p-3">

                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Gagnés</a>
                                    </template>
                                    <div class="row d-flex justify-content-between align-items-center mt-4">
                                        <div class="p-3">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <!-- Search -->
                                        <div class="inputCol p-3">
                                            <div class="input">
                                                <input type="search" id="serchInput" placeholder="Rechercher..."
                                                    v-model="filterActif" />
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="wonGiftdata
                                            " :fields="fieldsWinners" responsive="sm" :per-page="perPage"
                                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                            @row-clicked="goToUser">
                                            <template v-slot:cell(libelle)="row">
                                                <div style="text-align:left">
                                                    {{ row.item.libelle }}
                                                </div>

                                            </template>
                                            <template v-slot:cell(action)="row">
                                                <a v-if="row.item.discount == false" href="javascript:void(0);"
                                                    class="btn btn-success" @click="confirmValidation(row.item)"
                                                    v-b-tooltip.hover title="Valider">
                                                    Valider
                                                    <!-- <i class="mdi mdi-close font-size-18"></i> -->
                                                </a>

                                                <span v-else class="text-success" v-b-tooltip.hover title="Gain perçu">
                                                    Gain perçu
                                                </span>


                                                <!-- <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                                                    @click="deleteRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-delete font-size-18"></i>
                                                </a> -->

                                            </template>

                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">

                                                    <b-pagination v-model="currentPageActif" :total-rows="totalValueActif"
                                                        :per-page="perPageActif" :aria-controls="trajet"></b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title-link-class="p-3">

                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Échecs</a>
                                    </template>
                                    <div class="row d-flex justify-content-between align-items-center mt-4">
                                        <div class="p-3">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <!-- Search -->
                                        <div class="inputCol p-3">
                                            <div class="input">
                                                <input type="search" id="serchInput" placeholder="Rechercher..."
                                                    v-model="filterActif" />
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="loseGiftdata
                                            " :fields="fieldsLosers" responsive="sm" :per-page="perPage"
                                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                            @row-clicked="goToUser">
                                            <template v-slot:cell(libelle)="row">
                                                <div style="text-align:left">
                                                    {{ row.item.libelle }}
                                                </div>

                                            </template>
                                            <template v-slot:cell(action)="row">
                                                <a v-if="row.item.discount == false" href="javascript:void(0);"
                                                    class="btn btn-success" @click="confirmValidation(row.item)"
                                                    v-b-tooltip.hover title="Valider">
                                                    Valider
                                                    <!-- <i class="mdi mdi-close font-size-18"></i> -->
                                                </a>

                                                <span v-else class="text-success" v-b-tooltip.hover title="Gain perçu">
                                                    Gain perçu
                                                </span>


                                                <!-- <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                @click="deleteRow(row.item)" title="Editer">
                <i class="mdi mdi-delete font-size-18"></i>
            </a> -->

                                            </template>

                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">

                                                    <b-pagination v-model="currentPageActif" :total-rows="totalValueActif"
                                                        :per-page="perPageActif" :aria-controls="trajet"></b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>


                            </b-tabs>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal-backdrop" centered v-model="erreur" title="Veuillez remplir toutes les options."
            title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="info" class="ml-3 mr-4" @click="erreur = false">Ok</b-button>
            </div>
        </b-modal>



        <b-modal id="modal-backdrop" centered v-model="showValidate"
            title="Voulez-vous vraiment confirmer la réception de ce gain ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showValidate = false">Non</b-button>
                <b-button variant="success" class="ml-3 mr-4" @click="validateGain()">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-backdrop" centered v-model="showActive" title="Voulez-vous vraiment activé cet évènement ?"
            title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showActive = false">Non</b-button>
                <b-button variant="success" class="ml-3 mr-4" @click="activeevent()">Oui</b-button>
            </div>
        </b-modal>


        <b-modal id="modal-center" centered size="xl" v-model="showCreateGift" title="Ajouter un lot" title-class="font-18"
            hide-footer>

            <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
                <div class="row">


                    <div class="col-md-6 mb-3">
                        <label for="passName">Libellé</label>
                        <input type="text" class="form-control py-4" id="passName" v-model="gift.libelle" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="amount">Type</label>
                        <b-form-select v-model="gift.sort" size="lg" :options="options"></b-form-select>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="">Status</label>
                        <b-form-select v-model="gift.active" size="lg" :options="state"></b-form-select>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="">Type de Gain</label>
                        <b-form-select v-model="gift.giftType" size="lg" :options="giftType"></b-form-select>
                    </div>


                    <div class="col-md-6 mb-3" v-if="gift.giftType == 'REDUCTION'">
                        <label for="pourcentage">Pourcentage</label>
                        <input type="text" class="form-control py-4" size="lg" id="pourcentage" v-model="gift.percentage" />
                    </div>





                </div>
                <button class="btn btn-Back float-right" type="submit" @click="addGift()">
                    Enregistrer
                </button>
            </form>
        </b-modal>
        <b-modal id="modal-center" centered size="xl" v-model="showUpdateGift" title="Modifier ce lot" title-class="font-18"
            hide-footer>

            <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
                <div class="row">


                    <div class="col-md-6 mb-3">
                        <label for="passName">Libellé</label>
                        <input type="text" class="form-control py-4" id="passName" v-model="gift.libelle" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="amount">Type</label>
                        <b-form-select v-model="gift.sort" size="lg" :options="options"></b-form-select>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="">Status</label>
                        <b-form-select v-model="gift.active" size="lg" :options="state"></b-form-select>
                    </div>




                </div>
                <button class="btn btn-Back float-right" type="submit" @click="updateGift()">
                    Modifier
                </button>
            </form>
        </b-modal>

    </Layout>
</template>
<style >
tr td {
    text-align: left;
    text-wrap: nowrap;
    justify-content: left;
}

.add-gift {
    color: #fff;
    background-color: #3AAA35;
    border: 0px;
    border-radius: 8px;
    /* width: 150px;
    height: 50px; */
    padding: 15px;

}




.alignBtn {
    display: flex;
    float: right;

    align-items: flex-end;
}

.bg-secondary-special {
    background: #3aaa35;
}

.bg-secondary-smile {
    background: #3aaa35;
}

.widget-wrapping {
    display: flex !important;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
}

.thesize {
    font-size: 2em;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
}

.typeParrain {
    margin-top: 0.444rem;
    font-size: 17px;
    color: #fff;
}

.gain {
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
}

.fa-edit {
    font-size: 17px;
    cursor: pointer;
}

thead {
    background: #3aaa35;
}

thead div {
    color: #fff;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    margin-bottom: 5px;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

ion-icon {
    cursor: pointer;
}

.myBouton {
    background: transparent;
    text-align: center;
    outline: none;
    border: none;
}

.containerDot {
    z-index: 100;
}

.paging_simple_numbers {
    background: #3aaa35;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;

    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
    color: white !important;
}

.paging_simple_numbers ul li button {
    color: #fff !important;
}

.loading {
    font-size: 2rem;
    color: hsl(0, 0%, 12%);
}

.loading {
    font-weight: bold;
    font-size: 1.001rem;
    width: 100%;
    white-space: nowrap;
    color: hsl(0, 0%, 12%);
    text-align: center;
    display: inline-block;
    margin: 0 auto !important;
}

.otherThing {
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    width: 250px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
}

.containerDot {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    font-size: 2rem;
    color: hsl(0, 0%, 12%);
    width: 250px;
    height: 40px;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    margin: 0 auto;

}


.pac-container {
    z-index: 10000 !important;
}

.btn-Back {
    background: #3aaa35;
    color: #fff;
}


.modal-header {
    background: #3aaa35;

}


.modal-title {
    color: #fff;
}


.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: #fff !important;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35 !important;
    border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000 !important;
}
</style>
